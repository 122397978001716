<template>
  <div class="h-full w-full">
    <TaskSprintPopover
      ref="sprintRef"
      class="w-full h-full"
      button-class="flex items-center min-h-8"
      :selected-value="value"
      :disabled="disabled"
      :trigger-class="triggerClass"
      @update:open="onUpdateOpen"
      @select="handleSelect"
      @remove="handleRemove"
    >
      <div class="px-2 flex items-center z-10 h-full w-full pr-8">
        <span v-if="selectedBoardSprint" class="truncate">{{ selectedBoardSprint.sprint.name }}</span>
        <div
          v-else
          class="leading-4 text-xs group-hover/cell:visible invisible"
        >
          -
        </div>
        <div
          class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
        >
          <UButton
            v-if="selectedBoardSprint && !disabled"
            color="gray"
            variant="ghost"
            size="2xs"
            icon="i-heroicons-x-mark"
            class="group-hover/cell:visible invisible"
            @click.prevent.stop="handleRemove"
          />
          <Icon
            v-else
            name="heroicons:chevron-down"
            class="text-gray-500 group-hover/cell:visible invisible mr-1"
            size="2xs"
          />
        </div>
      </div>
    </TaskSprintPopover>
  </div>
</template>

<script lang="ts" setup>
import type { TaskFieldRef } from '#field/types'

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    default: undefined,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { boardSprints } = useBoardSprintsLoader()
const { updateTasks } = useUpdateTask()
const selectedBoardSprint = computed(() => {
  return boardSprints.value?.find((boardSprint) => boardSprint.id === props.value)
})

const sprintRef = ref()

const onUpdateOpen = (open: boolean) => {
  emit('update:open', open)
}

const handleSelect = (boardSprint: string) => {
  updateTasks([props.taskId], { boardSprint }, true)
  emit('update:open', false)
}

const handleRemove = () => {
  updateTasks([props.taskId], { boardSprint: '' }, true)
  emit('update:open', false)
}

defineExpose<TaskFieldRef>({
  open: () => {
    sprintRef.value?.open()
  },
  close: () => {
    sprintRef.value?.close()
  },
  delete: () => {
    handleRemove()
  },
})
</script>
